<template>
    <interact draggable :dragOption="dragOption" resizable :resizeOption="resizeOption" class="window window-style" :style="style" @dragmove="dragmove" @resizemove="resizemove" @click.native="setActiveWindow" :class="{ fullscreen: $store.getters.getWindowFullscreen(this.ComponentName), minimize: $store.getters.getWindowById(ComponentName).windowState=='minimize'}">
        <div class="top-bar" id="top-bar" @dblclick="toggleWindowSize">
            <h3 class="window-name">{{this.window.displayName}}</h3>
            <div class="triple-button">
                <button class="expand-button button" @click="toggleWindowSize"></button>
                <button class="minimize-button button" @click="minimizeWindow"></button>
                <button class="close-button button" @click="closeWindow"></button>
            </div>
        </div>
        <div class="content">
            <h2>CONTACT</h2>
            <form
                    action="https://formspree.io/f/xyzyyjpn"
                    method="POST"
                    class="email-form"
            >
                <label>
                    Email:
                </label>
                <input type="email" name="email" required>

                <label style="margin-top: 20px;">
                    Message:
                </label>
                <textarea style="margin-bottom: 20px;" name="message" required></textarea>

                <button type="submit" class="submit-button">Send</button>
            </form>
        </div>
    </interact>
</template>

<style scoped>
    /*-------------------------------------------*\
        Windows/Display
    \*-------------------------------------------*/

    .minimize {
        display: none;
    }

    .window {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
        min-height: 60vh;
        min-width: 350px;
        user-select: none;
        -ms-touch-action: none;
        touch-action: none;
        flex-flow: column;
        display: flex;
        border-radius: 12px; /* Rounded corners */
        background: #fff; /* White background for the window */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    }

    .fullscreen {
        width: 100% !important;
        height: var(--fullscreen) !important;
        margin: 0;
        transition: all 0.5s ease;
        padding: 0;
    }

    .content {
        flex-grow: 1;
        overflow-x: hidden;
        padding-left: 100px; /* Padding around content */
        padding-right: 100px;
        padding-top: 50px;
    }

    h2 {
        margin: 0 0 15px 0; /* Margin below header */
        font-family: 'Helvetica Neue', sans-serif; /* Mac-like font */
        font-size: 24px; /* Larger font for header */
    }

    .email-form {
        display: flex;
        flex-direction: column; /* Stack the form elements */
    }

    label {
        margin-bottom: 15px; /* Space between fields */
        font-family: 'Helvetica Neue', sans-serif; /* Mac-like font */
    }

    input, textarea {
        padding: 10px; /* Padding inside inputs */
        border: 1px solid #ccc; /* Light border */
        border-radius: 8px; /* Rounded corners */
        font-size: 16px; /* Font size for inputs */
        font-family: 'Helvetica Neue', sans-serif; /* Mac-like font */
        transition: border-color 0.3s; /* Transition for border color */
    }

    input:focus, textarea:focus {
        border-color: #007AFF; /* Blue border on focus */
        outline: none; /* Remove default outline */
    }

    textarea {
        resize: none; /* Disable resizing */
        height: 100px; /* Fixed height for textarea */
    }

    .submit-button {
        padding: 10px 15px; /* Padding for button */
        background-color: #007AFF; /* Blue background */
        color: white; /* White text */
        border: none; /* Remove border */
        border-radius: 8px; /* Rounded corners */
        cursor: pointer; /* Pointer cursor on hover */
        font-size: 16px; /* Font size for button */
        transition: background-color 0.3s; /* Transition for background color */
    }

    .submit-button:hover {
        background-color: #005BB5; /* Darker blue on hover */
    }
</style>

<script>
    import interact from "interactjs";
    export default {
        props: {
            'nameOfWindow': String,
            content_padding_left: {
                required: false,
                type: String,
                default: '15%',
            },
            content_padding_right: {
                required: false,
                type: String,
                default: '15%'
            },
            content_padding_top: {
                required: false,
                type: String,
                default: '5%'
            },
            content_padding_bottom: {
                required: false,
                type: String,
                default: '5%'
            },
        },
        data: function () {
            return {
                // ID Name of window (important)
                ComponentName: this.nameOfWindow,

                // window
                Window: {},

                // InteractJS states and modifiers
                resizeOption: {
                    edges: {
                        top: true,
                        left: true,
                        bottom: true,
                        right: true
                    },
                    margin: 8,
                },
                dragOption: {
                    modifiers: [
                        interact.modifiers.restrictRect({
                            restriction: "#screen",
                            endOnly: true
                        })
                    ],
                    allowFrom: '.top-bar',
                },
                // values for interact.js transformation
                x: 0,
                y: 0,
                w: 400,
                h: 400,
            }
        },
        computed: {
            style() {
                return {
                    height: `${this.h}px`,
                    width: `${this.w}px`,
                    transform: `translate(${this.x}px, ${this.y}px)`,
                    '--content-padding-left': this.content_padding_left || '15%',
                    '--content-padding-right': this.content_padding_right || '15%',
                    '--content-padding-top': this.content_padding_top || '5%',
                    '--content-padding-bottom': this.content_padding_bottom || '5%',
                    '--fullscreen': this.$store.getters.getFullscreenWindowHeight
                };
            }
        },
        created() {
            this.window = this.$store.getters.getWindowById(this.ComponentName);
        },
        methods: {
            closeWindow() {
                const payload = {
                    'windowState': 'close',
                    'windowID': this.ComponentName
                }
                this.$store.commit('setWindowState', payload);
            },
            openWindow() {
                const payload = {
                    'windowState': 'open',
                    'windowID': this.ComponentName
                }
                this.$store.commit('setWindowState', payload);
            },
            minimizeWindow() {
                const payload = {
                    'windowState': 'minimize',
                    'windowID': this.ComponentName
                }
                this.$store.commit('setWindowState', payload);
            },
            toggleWindowSize() {
                if (this.$store.getters.getWindowFullscreen(this.ComponentName) == true) {
                    const payload = {
                        'fullscreen': false,
                        'windowID': this.ComponentName
                    }
                    this.$store.commit('setFullscreen', payload);
                    this.x = this.tempX;
                    this.y = this.tempY;
                } else if (this.$store.getters.getWindowFullscreen(this.ComponentName) == false) {
                    const payload = {
                        'fullscreen': true,
                        'windowID': this.ComponentName
                    }
                    this.$store.commit('setFullscreen', payload);
                    const tempX = this.x;
                    const tempY = this.y;
                    this.tempX = tempX;
                    this.tempY = tempY;
                    this.x = 0;
                    this.y = 0;
                }
            },
            setActiveWindow() {
                this.$store.commit('zIndexIncrement', this.ComponentName);
                this.$store.commit('setActiveWindow', this.ComponentName);
            },
            dragmove(event) {
                this.x += event.dx;
                this.y += event.dy;
                this.setActiveWindow();
            },
            resizemove(event) {
                this.w = event.rect.width;
                this.h = event.rect.height;
                this.x += event.deltaRect.left;
                this.y += event.deltaRect.top;
            }
        }
    }
</script>
